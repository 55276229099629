import * as React from "react"

const SalesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{``}</style>
    <path d="m180.5 1604.8-31.4-44.1L606 1235.4l12.9-198.1L1050.7 730l13.7-210.1 477.8-340.1 31.4 44.1-456.8 325.2-12.1 185.3s-.3 4.5-.8 11.9l-.8 12.9-431.8 307.4-12.9 198.1-477.9 340.1z" />
    <path d="M1687.3 109.5 1583.6 347l-153.8-208.5zM670.4 1658.2H429.5c-12.6 0-22.9-10.2-22.9-22.9V1533c0-56.6 45.9-102.5 102.5-102.5h161.3c12.6 0 22.9 10.2 22.9 22.9v181.9c0 12.6-10.3 22.9-22.9 22.9zM1084.9 1658.2H840.8c-11.8 0-21.3-9.5-21.3-21.3V1144c0-53.4 43.3-96.8 96.8-96.8h168.6c11.8 0 21.3 9.5 21.3 21.3v568.4c0 11.7-9.6 21.3-21.3 21.3zM1495.8 1658.2h-251.2c-9.8 0-17.8-8-17.8-17.8V780.2c0-74.2 60.2-134.4 134.4-134.4h134.5c9.8 0 17.8 8 17.8 17.8v976.8c0 9.8-7.9 17.8-17.7 17.8zM486 140.9c-184.2 0-333.5 149.3-333.5 333.5S301.8 807.9 486 807.9s333.5-149.3 333.5-333.5S670.2 140.9 486 140.9zm59.6 514.3h-176v-45c36.7-16.9 55.1-44.8 55.1-83.8v-5.6l-.6-3.9h-54.5v-43.3h38.2c-25.5-38.2-38.2-71.8-38.2-100.7 0-31.1 11-57.4 32.9-79s49.6-32.3 82.9-32.3c27 0 50.3 7.3 70 21.9 19.7 14.6 33.5 33.9 41.3 57.9l-47.2 19.7c-10.5-33.4-31.9-50-64.1-50-18.7 0-34.1 6-46.1 18s-18 26.6-18 43.9c0 18 6.7 38.4 20.2 61.3s21 36 22.5 39.4h75.9V517h-64.7c0 36-11.1 65-33.2 87.2l1.7 1.7h100.1c23.2 0 39.2-10.7 47.8-32.1l42.7 18c-16.8 41.5-46.4 62.6-88.7 63.4z" />
  </svg>
)

export { SalesIcon }