import * as React from "react"

const DocumentsIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.st0{fill:none} path:hover{${props.hovercolor}}`}</style>
    <path d="M1568 386c-50.3-50.3-117.3-78.1-188.5-78.1h-258.4c11.8 14 22.1 29.4 30.5 45.8h227.8c121.7 0 220.8 99 220.8 220.8v884.2h-421.8v45.8H1646v-930c.1-71.2-27.7-138.2-78-188.5z" />
    <path d="M1178.5 479.8h316v25h-316zM1178.5 555.1h317.6v25h-317.6zM1178.5 626.4h317.6v25h-317.6zM1178.5 691.7h315.2v25h-315.2zM1178.5 767h316.8v25h-316.8zM1178.5 838.2h316.8v25h-316.8zM1178.5 909.5h316.8v25h-316.8zM1178.5 984.8h318.4v25h-318.4zM1178.5 1056.1h318.4v25h-318.4zM1178.5 1127.3h320v25h-320zM1178.5 1198.6h320v25h-320zM1178.5 1273.9h321.6v25h-321.6zM1178.5 1345.2h321.6v25h-321.6z" />
    <path d="M1201.4 1614.1h-986V198.3h719.4c71.2 0 138.1 27.7 188.5 78.1 50.3 50.3 78.1 117.3 78.1 188.5v1149.2zm-940.2-45.8h894.3V464.9c0-121.7-99-220.8-220.8-220.8H261.2v1324.2z" />
    <path d="M378.9 399h646.4v29.2H378.9zM380.8 488.5h646.4v29.2H380.8zM380.8 573.4h646.4v29.2H380.8zM378 651.2h646.4v29.2H378zM379.8 740.7h646.4v29.2H379.8zM379.8 825.6h646.4v29.2H379.8zM379.8 910.5h646.4v29.2H379.8zM381.7 1000h646.4v29.2H381.7zM381.7 1084.9h646.4v29.2H381.7zM383.6 1169.7H1030v29.2H383.6zM383.6 1254.6H1030v29.2H383.6zM385.5 1344.2h646.4v29.2H385.5zM385.5 1429h646.4v29.2H385.5z" />
    <path className="st0" d="M1178.5 504.8v-25M1178.5 651.4v-25" />
  </svg>
)

export { DocumentsIcon }