import * as React from "react"

const JobCostingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{``}</style>
    <path d="M708.3 456.6V146.8h-10.4c-37.5 0-74.3 6.4-109.2 19.1-33.8 12.3-65.1 30-93.1 52.9s-51.9 50.3-70.6 80.8c-18.7 30.5-32.4 64.1-40 99.2l-.3 1.5c-3.5 16.6-5.7 33.5-6.6 50.6-.3 5.4-.4 10.7-.4 16.1 0 41.1 7.7 81 22.4 118.2 12.5 31.5 30 60.9 52.2 87.3 6.1 7.2 12.5 14.2 19.2 21 60.5 60.5 140.9 93.8 226.4 93.8 38.5 0 76-6.8 111.1-19.7 32.1-11.9 62.3-28.9 89.3-50.7 5.8-26.2 26.3-46.9 52.3-53.1 31.2-39.9 52.2-86.2 61.7-135.6 3.8-19.9 5.8-40.3 5.8-61v-10.4H708.3zM809 745c-34.4 13.8-71.9 21.4-111.1 21.4-101.6 0-191.5-50.8-245.6-128.4-13.8-19.7-25.2-41.1-33.9-63.9-11-28.7-17.8-59.5-19.4-91.6-.3-5.1-.4-10.3-.4-15.5 0-10.7.6-21.5 1.7-32.1.3-3.1.7-6.1 1.1-9.2 1-7.5 2.4-15 4-22.4C434.3 270.1 552 172.6 687.6 167.8v309.6h309.6c-.6 17.3-2.7 34.2-6.1 50.6-20.6 98.9-90.2 180.1-182.1 217z" />
    <path d="M1066 388.8H773v-293c159.6 4.3 288.6 133.3 293 293zM239.5 550v209c0 15.3-12.5 27.8-27.8 27.8h-2c-15.3 0-27.8-12.5-27.8-27.8V550c0-15.3 12.5-27.8 27.8-27.8h2c15.4 0 27.8 12.5 27.8 27.8zM345.9 461.2V761c0 15.3-12.5 27.8-27.8 27.8h-2c-15.3 0-27.8-12.5-27.8-27.8V461.2c0-15.3 12.5-27.8 27.8-27.8h2c15.3 0 27.8 12.5 27.8 27.8zM452.3 601.6V759c0 15.3-12.5 27.8-27.8 27.8h-2c-15.3 0-27.8-12.5-27.8-27.8V601.6c0-15.3 12.5-27.8 27.8-27.8h2c15.3 0 27.8 12.5 27.8 27.8z" />
    <path d="M426.8 524.9 320.4 363.2 212.8 470.9 125.9 384l23.5-23.6 63.4 63.4 113-113 106.3 161.6 29.6-29.5 23.5 23.5zM1462.9 528H974.6C883.2 528 809 602.2 809 693.6v816.1c0 91.5 74.2 165.6 165.6 165.6h488.3c91.5 0 165.6-74.2 165.6-165.6V693.6c.1-91.4-74.1-165.6-165.6-165.6zm-480.3 937.9c-37.6 0-68-30.4-68-68s30.4-68 68-68c37.5 0 68 30.4 68 68 0 37.5-30.5 68-68 68zm1.8-215.9c-37.5 0-68-30.4-68-68s30.5-68 68-68c37.6 0 68 30.4 68 68s-30.4 68-68 68zm0-215.9c-37.5 0-68-30.4-68-68s30.5-68 68-68c37.6 0 68 30.4 68 68s-30.4 68-68 68zm233.4 431.8c-37.6 0-68-30.4-68-68s30.4-68 68-68 68 30.4 68 68c0 37.5-30.4 68-68 68zm1.8-215.9c-37.6 0-68-30.4-68-68s30.4-68 68-68c37.5 0 68 30.4 68 68s-30.4 68-68 68zm0-215.9c-37.6 0-68-30.4-68-68s30.4-68 68-68c37.5 0 68 30.4 68 68s-30.4 68-68 68zm303.3 363.2c0 38.8-31.4 70.2-70.2 70.2h-3.8c-38.8 0-70.2-31.4-70.2-70.2v-213.1c0-38.8 31.4-70.2 70.2-70.2h3.8c38.8 0 70.2 31.4 70.2 70.2v213.1zm-144.2-431.2c0-37.6 30.4-68 68-68s68 30.4 68 68-30.4 68-68 68-68-30.4-68-68zm162.2-230.8c0 38.8-31.6 70.3-70.3 70.3H967c-38.8 0-70.3-31.5-70.3-70.3V732c0-5.2.6-10.4 1.7-15.3 5.8-26.2 26.3-46.9 52.3-53.1 5.2-1.2 10.7-1.9 16.3-1.9h503.6c38.8 0 70.3 31.5 70.3 70.3v3.3z" />
    <path d="M1470.6 682.5H967c-27.3 0-49.5 22.2-49.5 49.5v3.3c0 27.3 22.2 49.5 49.5 49.5h503.6c27.3 0 49.5-22.2 49.5-49.5V732c0-27.3-22.2-49.5-49.5-49.5z" />
  </svg>
)

export { JobCostingIcon }