import * as React from "react"

const FeedbackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{``}</style>
    <path d="M1454.6 326.5H325.4c-110.4 0-199.9 89.5-199.9 199.9v529.5c0 110.4 89.5 199.9 199.9 199.9h356.2c16.8 0 26.6 18.7 17.2 32.6-11.5 16.9-24 34-37.6 51.4-48.7 61.9-100.2 111.2-148.5 150.1-14.1 11.4-2.9 34.1 14.7 29.7 5.6-1.4 11.3-2.8 16.9-4.3 204.9-53.9 370.2-149.1 497-245.2 12.2-9.2 27-14.2 42.3-14.2h371c110.4 0 199.9-89.5 199.9-199.9V526.4c0-110.4-89.5-199.9-199.9-199.9zM305 783.2c0-25.2 20.5-45.7 45.7-45.7H907c25.2 0 45.7 20.5 45.7 45.7v15.9c0 25.2-20.5 45.7-45.7 45.7H350.8c-25.2 0-45.7-20.5-45.7-45.7v-15.9zm881.7 232.3c0 25.2-20.5 45.7-45.7 45.7H350.8c-25.2 0-45.7-20.5-45.7-45.7v-15.9c0-25.2 20.5-45.7 45.7-45.7H1141c25.2 0 45.7 20.5 45.7 45.7v15.9zm303.8-432.7c0 25.2-20.5 45.7-45.7 45.7h-1094c-25.2 0-45.7-20.5-45.7-45.7v-15.9c0-25.2 20.5-45.7 45.7-45.7h1094.1c25.2 0 45.7 20.5 45.7 45.7v15.9z" />
  </svg>
)

export { FeedbackIcon }