import { AppsIcon } from "./assets/svgs/AppsIcon";
import { AttendanceIcon } from "./assets/svgs/AttendanceIcon";
import { ContactsIcon } from "./assets/svgs/ContactsIcon";
import { ContractsIcon } from "./assets/svgs/ContractsIcon";
import { CustomersIcon } from "./assets/svgs/CustomersIcon";
import { DocumentsIcon } from "./assets/svgs/DocumentsIcon";
import { FeedbackIcon } from "./assets/svgs/FeedbackIcon";
import { FleetIcon } from "./assets/svgs/FleetIcon";
import { HQIcon } from "./assets/svgs/HQIcon";
import { JobCostingIcon } from "./assets/svgs/JobCostingIcon";
import { MarketingIcon } from "./assets/svgs/MarketingIcon";
import { PurchasingIcon } from "./assets/svgs/PurchasingIcon";
import { SalesIcon } from "./assets/svgs/SalesIcon";
import { SocialIcon } from "./assets/svgs/SocialIcon";
import { SupportIcon } from "./assets/svgs/SupportIcon";

const iconImages = {
  BookingIcon: {
    link: false,
    name: "test",
    icon: AppsIcon
  },

  BusinessesIcon: {
    link: false,
    name: "Business",
    icon: ContractsIcon,
  },

  contract: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Contracts+Icon.svg",
    name: "Contract",
    icon: ContractsIcon
  },

  DocumentIcon: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Documents+Icon.svg",
    name: "Documents",
    icon: DocumentsIcon
  },

  FeedbackIcon: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Feedback+Icon.svg",
    name: "Feedback",
    icon: FeedbackIcon
  },
    
  fleet: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Fleet+Icon.svg",
    name: "Fleet",
    icon: FleetIcon
  },

  "fleet-management": {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Fleet+Icon.svg",
    name: "Fleet",
    icon: FleetIcon
  },

  FulfilmentIcon: {
    link: false,
    name: "Fulfilment",
    icon: AppsIcon
  },

  HealthNSaftyIcon: {
    link: false,
    name: "Health and Safety",
    icon: AppsIcon
  },

  hq: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/HQ+Icon.svg",
    name: "HQ",
    icon: HQIcon
  },

  HRIcon: {
    link: false,
    name: "HR",
    icon: CustomersIcon
  },

  marketing: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Marketing+Icon.svg",
    name: "Marketing",
    icon: MarketingIcon
  },

  gtm: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Marketing+Icon.svg",
    name: "Marketing",
    icon: MarketingIcon
  },

  ReportingIcon: { 
    link: false,
    name: "Report",
    icon: AppsIcon
  },

  sale: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Sales+Icon.svg",
    name: "Sales",
    icon: SalesIcon
  },

  support: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Support+Icon.svg",
    name: "Support",
    icon: SupportIcon
  },

  attendance: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Time+%26+Attendance+Icon.svg",
    name: "Time & Attendance",
    icon: AttendanceIcon
  },

  "time-and-attendance": {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Time+%26+Attendance+Icon.svg",
    name: "Time & Attendance",
    icon: AttendanceIcon
  },

  attend: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Time+%26+Attendance+Icon.svg",
    name: "Time & Attendance",
    icon: AttendanceIcon
  },

  UserIcon: {
    link: false,
    name: "Me",
    icon: CustomersIcon
  },

  jobcosting: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Job+Costing+Icon.svg",
    name: "Job Costing",
    icon: JobCostingIcon
  },
  purchasing: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Purchasing+Icon.svg",
    name: "Purchasing",
    icon: PurchasingIcon
  },
  social: {
    link: "https://mybe-public.s3.eu-west-2.amazonaws.com/app_icons/svgs/Social+Icon.svg",
    name: "Social",
    icon: SocialIcon
  }
};

const nameMapping = {
  SaddingtonTaylor: {
    name: "st"
  }
};

export { iconImages, nameMapping };
