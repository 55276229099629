import * as React from "react"

const AttendanceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{``}</style>
    <path d="M1280.7 701.9c-30.5-72.2-74.2-137-129.9-192.7-55.7-55.7-120.5-99.4-192.7-129.9-74.8-31.6-154.2-47.6-236-47.6s-161.2 16-236 47.6c-72.2 30.5-137 74.2-192.7 129.9-55.7 55.7-99.4 120.5-129.9 192.7-31.6 74.8-47.6 154.1-47.6 236 0 81.8 16 161.2 47.6 236 30.5 72.2 74.2 137 129.9 192.7 55.7 55.7 120.5 99.4 192.7 129.9 74.8 31.6 154.2 47.7 236 47.7s161.2-16 236-47.7c2.2-.9 4.5-1.9 6.7-2.9V1415c-72.9 37.2-155.4 58.2-242.7 58.2-295.2 0-535.4-240.2-535.4-535.4s240.2-535.4 535.4-535.4 535.4 240.2 535.4 535.4c0 67-12.3 131.1-34.9 190.2h75.3c20.2-61.1 30.4-124.9 30.4-190.2 0-81.8-16-161.2-47.6-235.9z" />
    <path d="M686.7 367h70.8v128.4h-70.8zM686.7 1380.3h70.8v128.4h-70.8zM151.3 902.4h128.4v70.8H151.3zM1164.5 912.9h128.4v50h-128.4zM964.8 973.2H722.1v-35.4l-35.4-.2V590.5h70.8v311.9h207.3v70.8z" />
    <circle cx={1289.8} cy={899.8} r={218.3} />
    <path d="M1289.8 1143.1c-65 0-126.1-25.3-172-71.3-46-46-71.3-107-71.3-172s25.3-126.1 71.3-172c46-46 107-71.3 172-71.3s126.1 25.3 172 71.3c46 46 71.3 107 71.3 172s-25.3 126.1-71.3 172c-45.9 45.9-107 71.3-172 71.3zm0-436.6c-106.6 0-193.3 86.7-193.3 193.3s86.7 193.3 193.3 193.3c106.6 0 193.3-86.7 193.3-193.3s-86.7-193.3-193.3-193.3z" />
    <path d="M1653.7 1533.6H939.8v-226.3c0-112.6 91.6-204.2 204.2-204.2h305.4c112.6 0 204.2 91.6 204.2 204.2v226.3zm-663.9-49.9h613.9v-176.4c0-85.1-69.2-154.2-154.2-154.2H1144c-85.1 0-154.2 69.2-154.2 154.2v176.4zM1310.1 603.1l-25-25-135.5-135.5 50.1-50.1L1310.2 503l256.6-256.6 50.1 50.1-281.7 281.7-25.1 24.9z" />
  </svg>
)

export { AttendanceIcon }
