import * as React from "react"

const SupportIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{``}</style>
    <path d="M452 1327.5c-163.2 0-295.4-132.3-295.4-295.4S288.8 736.6 452 736.6v590.9z" />
    <path d="M497.8 1373.3H452c-46 0-90.7-9-132.8-26.8-40.6-17.2-77.1-41.8-108.5-73.1s-55.9-67.8-73.1-108.5c-17.8-42.1-26.8-86.8-26.8-132.8s9-90.7 26.8-132.8c17.2-40.6 41.8-77.1 73.1-108.5 31.3-31.3 67.8-55.9 108.5-73.1 42.1-17.8 86.8-26.8 132.8-26.8h45.8v682.4zm-91.6-586.6c-115.8 21.6-203.8 123.4-203.8 245.4s88 223.8 203.8 245.4V786.7zM1327.9 1327.5c163.2 0 295.4-132.3 295.4-295.4S1491 736.7 1327.9 736.7v590.8z" />
    <path d="M1327.9 1373.3h-45.8V690.8h45.8c46 0 90.7 9 132.8 26.8 40.6 17.2 77.1 41.8 108.5 73.1 31.3 31.3 55.9 67.8 73.1 108.5 17.8 42.1 26.8 86.8 26.8 132.8s-9 90.7-26.8 132.8c-17.2 40.6-41.8 77.1-73.1 108.5s-67.8 55.9-108.5 73.1c-42 17.9-86.7 26.9-132.8 26.9zm45.8-586.6v490.8c115.8-21.6 203.8-123.4 203.8-245.4s-87.9-223.9-203.8-245.4z" />
    <path d="M1495.8 761.7h-125c0-269.9-219.6-489.6-489.6-489.6-269.9 0-489.6 219.6-489.6 489.6h-125c0-82.9 16.3-163.4 48.3-239.2 31-73.2 75.3-138.9 131.7-195.3 56.4-56.4 122.1-100.7 195.3-131.7 75.8-32.1 156.3-48.3 239.2-48.3s163.4 16.3 239.2 48.3c73.2 31 138.9 75.3 195.3 131.7 56.4 56.4 100.7 122.1 131.7 195.3 32.3 75.8 48.5 156.2 48.5 239.2zM1335 1615.7l-13.7-90.6c65.6-9.9 120.3-30.9 162.6-62.3 63.3-46.9 82.9-140.1 88.1-209.9 5.1-67.9-1.8-127.7-5.3-152.1-.5-2.5-.9-4.5-1.1-6.1-.5-2.9-.9-5.3-1.2-7.2-.2-1.5-.4-2.5-.4-3.3-2.4-21.3 9.8-41.3 29.7-48.6l56.1 21.3c3.8 7.1 5 12.6 7.5 29.3v.2c.8 4.2 1.9 9.4 3.2 15.7l-1.1.2c.8 6.6 1.7 14.4 2.5 23.2 2.7 29.3 5.3 74.8 2.2 125-3.5 57-13.5 108.1-29.7 151.9-21.4 57.8-53.6 102.9-95.9 134.2-54.6 40.2-123 66.9-203.5 79.1zm250.6-497.9c8.8 5.4 19.7 8 31.7 5.9 13.3-2.3 23.4-9.6 29.8-18.9l-61.5 13z" />
    <path d="M890 1358.6c-45.8 0-90.3-9-132.1-26.7-40.4-17.1-76.7-41.6-107.9-72.7-31.2-31.2-55.6-67.5-72.7-107.9-17.7-41.9-26.7-86.3-26.7-132.1s9-90.3 26.7-132.1c17.1-40.4 41.6-76.7 72.7-107.9 31.2-31.2 67.5-55.6 107.9-72.7 41.9-17.7 86.3-26.7 132.1-26.7s90.3 9 132.1 26.7c40.4 17.1 76.7 41.6 107.9 72.7s55.6 67.5 72.7 107.9c17.7 41.9 26.7 86.3 26.7 132.1s-9 90.3-26.7 132.1c-17.1 40.4-41.6 76.7-72.7 107.9s-67.5 55.6-107.9 72.7c-41.8 17.7-86.3 26.7-132.1 26.7zm0-629c-159.6 0-289.5 129.9-289.5 289.5s129.9 289.5 289.5 289.5 289.5-129.9 289.5-289.5S1049.6 729.6 890 729.6z" />
    <text
      transform="translate(727.559 1257.115)"
      style={{
        fontSize: "640.1899px",
        fontFamily: "'ProductSans-Bold'",
      }}
    >
      {"?"}
    </text>
  </svg>
)

export { SupportIcon }